.address-list {
    display: flex;
    flex-direction: column;
    padding: 20px;
    max-width: 50%;
}

.address-entry {
    display: flex;
    align-items: center;  /* Align items in a row vertically */
    margin-bottom: 10px;
}

.address-entry label {
    display: flex;
    align-items: center;  /* Ensure label content is aligned */
    width: 100%;  /* Full width to enclose radio button and text */
}

.address-entry input[type="radio"] {
    margin-right: 10px;  /* Space between radio button and text */
}

.add-address-button {
    padding: 10px 20px;
    margin-top: 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.add-address-button:hover {
    background-color: #0056b3;
}

@media (max-width: 768px) {
    .address-list {
        max-width: 100%;
    }
}