.product-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
}

.product-image {
    width: 100%;
    max-width: 300px;
    height: auto;
    border-radius: 8px;
    margin-bottom: 20px;
}

.product-details {
    text-align: center;
    margin-bottom: 20px;
}

.product-name {
    font-size: 1.7em;
    margin-bottom: 10px;
    padding: 0 10px;
}

.product-points {
    font-size: 1.4em;
    margin: 5px 0;
}

.product-point-strike{
    font-size: 1em;
}
.buy-now-discount{
    color: red;
}

.product-billing {
    font-size: 1.2em;
    margin: 10px 0;
}

.product-actions {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-bottom: 10px;
}

.product-button {
    background-color: #007bff;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 4px;
    font-size: 1em;
    transition: background-color 0.3s;
}

.add-to-cart-button {
    border: 1px solid black;
    background-color: white;
    color: black;
}

.buy-now-button {
    background-color: #145c49;
    color: #ffdd8f;
}

.product-description {
    text-align: center;
    width: 100%;
    margin-top: 0px;
    margin-left: 0%;
}

.product-description p {
    text-align: left;
    padding: 0 20px;
}

.product-description h4 {
    font-size: 1.5em;
    margin-bottom: 10px;
}

.product-description p {
    font-size: 1em;
    line-height: 1.5;
}

@media (max-width: 768px) {
    .product-container {
        padding: 10px;
    }

    .product-details {
        margin-bottom: 5px;
    }

    .product-name {
        font-size: 1.7em;
    }

    .product-points,
    .product-billing {
        font-size: 1.2em;
    }

    .product-description {
        margin-top: 5px;
    }

    .product-description h4 {
        font-size: 1.2em;
    }

    .product-description p {
        font-size: 1em;
    }
}
