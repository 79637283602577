.footer {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #145c49;
  color: white;
  padding: 10px 0;
  position: fixed;
  bottom: 0;
  width: 100%;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
}

.footer-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  position: relative;
  height: 100%;
  padding: 0px 12px;
}

.footer-item span {
  margin-top: 5px;
  font-size: 12px;
}

.footer-item:hover {
  color: #ddd;
}

.footer-item.active {
  color: #ffdd8f; /* Highlight color for the active page */
  /* background-color: yellow; */
}

.cart-count {
  position: absolute;
  top: 0;
  right: 10px;
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 2px 6px;
  font-size: 10px;
}

.footer-info {
  text-align: center;
}

.footer-info p {
  margin: 5px 0;
}

@media (max-width: 768px) {
  .footer {
      padding: 10px 0;
  }

  .footer-item span {
      font-size: 10px;
  }

  .footer-info p {
      font-size: 10px;
  }
}
