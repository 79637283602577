.login-box {
    width: 350px;
    padding: 2rem;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    background-color: #fff;
    margin-top: 12%;
    margin-left:40%;
  }
  
  .login-box h2 {
    margin-bottom: 1rem;
  }
  
  .login-box input {
    width: 100%;
    padding: 0.5rem;
    margin: 0.5rem 0;
    border-radius: 4px;
    border: 1px solid #ccc;
  }
  
  .login-box button {
    width: 100%;
    padding: 0.5rem;
    border-radius: 4px;
    border: none;
    background-color: #145c49;
    color: #ffdd8f;
    cursor: pointer;
  }
  
  .error {
    color: red;
    margin-bottom: 10px;
  }
  
  @media (max-width: 500px) {
    .login-box {
        width: 90%;
        margin-left: 5%;
        margin-top: 45%;
        height: auto;
    }
  }
