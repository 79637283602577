.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #145c49;
  padding: 1rem;
  color: white;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  box-sizing: border-box;
  z-index: 1000;
}

.header-left, .header-right {
  display: flex;
  align-items: center;
}

.header-logo {
  font-size: 1.5em;
  color: white;
  cursor: pointer;
}

.header-right {
  justify-content: flex-end;
}

.header-points {
  margin-right: 20px;
  background-color: #145c49;
  padding: 5px 10px;
  border-radius: 5px;
  text-align: center;
  color: #ffdd8f;
  font-size: .8em;
}

.your-points{
  font-size: 1.75em;
}

.header-points div {
  margin: 0;
}

.header-tier {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 8px;
  color: black;
  font-weight: bold;
  text-align: center;
  width: 90px;
  height: 40px;
  margin-right: 0;
  margin-left: 15px;
}

.tier-value {
  font-size: 1.15em;
  padding: 0;
}

.user-icon {
  cursor: pointer;
  position: relative;
  margin-left: 10px;
}

.dropdown {
  position: absolute;
  top: 45px;
  right: 0;
  background-color: white;
  color: black;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  width: 150px;
}

.dropdown-item {
  padding: 10px;
  cursor: pointer;
  border-bottom: 1px solid #ccc;
}

.dropdown-item:last-child {
  border-bottom: none;
}

.dropdown-item:hover {
  background-color: #f0f0f0;
}

.dropdown-item:first-child:hover {
  background-color: white;
}

.username {
  cursor: default;
  font-weight: bold;
  text-align: center;
  font-size: 1em;
  border-bottom: 1px solid #ccc;
}

.header-nav {
  display: flex;
  justify-content: center;
  flex-grow: 1;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.header-nav button {
  background: none;
  border: none;
  color: white;
  margin: 0 10px;
  cursor: pointer;
  font-size: 1em;
}

.header-nav button.active {
  border-bottom: 2px solid red;
}

.header-nav button:hover {
  color: #ddd;
}

@media (max-width: 768px) {
  .header-nav{
    margin-left: 30px;
  }
  .header-logo{
    font-size: 1.2em;
  }
}
