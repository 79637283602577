.item-details {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

.item-image {
    width: 100%;
    max-width: 300px;
    height: auto;
    margin-bottom: 20px;
}

.item-details label,
.item-details select,
.item-details button {
    margin-top: 10px;
}

.place-order-btn {
    padding: 10px 20px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
}

.place-order-btn:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

@media (max-width: 768px) {
    .item-details {
        width: 100%;
        align-items: center;
    }
}
